import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Nav from '../components/Nav';
import Post from '../components/Post';
import ReadMorePost from '../components/ReadMorePost';

const Blog = (props) => {
  const [shareableURL, setShareableURL] = useState(null);

  // Current post data.
  const {
    timeToRead,
    html,
    frontmatter: { title, date, dateModified },
    fields: { slug },
  } = props.data.markdownRemark;

  // For SEO.
  const {
    description,
    front_image: {
      publicURL,
      childImageSharp: { gatsbyImageData },
    },
  } = props.data.allMarkdownRemark.edges[0].node.frontmatter;

  // Read more posts data.
  const {
    frontmatter: {
      readMorePostTitle,
      readMorePostDescription,
      front_image: {
        childImageSharp: { readMorePostFluid },
      },
    },
    fields: { readMorePostSlug },
  } = props.data.readMorePosts.edges[0].node;

  useEffect(() => {
    setShareableURL(`${window.location.href}`);
  }, []);

  return (
    <Fragment>
      <Seo
        title={title}
        description={description}
        image={publicURL}
        articlePublishedTime={date}
        articleModifiedTime={dateModified}
        article
      />
      <Layout>
        <Nav returnLocation="blog" />

        <Post
          title={title}
          description={description}
          date={date}
          timeToRead={timeToRead}
          fluid={gatsbyImageData}
          html={html}
          url={shareableURL}
          slug={slug}
        />

        <ReadMorePost
          readMorePostDescription={readMorePostDescription}
          readMorePostTitle={readMorePostTitle}
          readMorePostFluid={readMorePostFluid}
          readMorePostSlug={readMorePostSlug}
        />
      </Layout>
    </Fragment>
  );
};

export default Blog;

export const query = graphql`
  query ($slug: String) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { type: { eq: "blog-post" } }
    ) {
      frontmatter {
        title
        date(formatString: "LL")
        dateModified: date_modified(formatString: "LL")
      }
      timeToRead
      html
      fields {
        slug
      }
    }

    # For SEO.
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { type: { eq: "blog-post" } }
        fields: { slug: { eq: $slug } }
      }
    ) {
      edges {
        node {
          frontmatter {
            description
            front_image {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  quality: 80
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }

    # For the read more section.
    readMorePosts: allMarkdownRemark(
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { type: { eq: "blog-post" }, status: { eq: "ready" } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          frontmatter {
            readMorePostTitle: title
            readMorePostDescription: description
            front_image {
              childImageSharp {
                readMorePostFluid: gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  quality: 80
                )
              }
            }
          }
          fields {
            readMorePostSlug: slug
          }
        }
      }
    }
  }
`;
