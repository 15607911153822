import React from 'react';
import { GatsbyImageProps } from 'gatsby-plugin-image';
import SharePost from '../Share';
import { PostHeader, PostCoverImage, PostContent } from '.';
import styles from './Post.module.scss';

export interface IPostProps {
  title: string;
  date: string;
  timeToRead: number;
  description: string;
  fluid: GatsbyImageProps['image'];
  html: string;
  url: string;
  slug: string;
}

const Post: React.FC<IPostProps> = ({
  title,
  date,
  timeToRead,
  description,
  fluid,
  html,
  url,
  slug,
}) => {
  return (
    <article className={styles.post}>
      <PostHeader title={title} date={date} timeToRead={timeToRead} />
      <PostCoverImage title={title} fluid={fluid} />
      <PostContent html={html} />
      <SharePost content={description} url={url} />
    </article>
  );
};

export default Post;
