import React from 'react';
import styles from './Content.module.scss';

export interface IPostContentProps {
  html: string;
}

const PostContent: React.FC<IPostContentProps> = ({ html }) => {
  return (
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

export default PostContent;
