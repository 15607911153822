import React from 'react';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import styles from './Post.module.scss';

export interface IPostCoverImageProps {
  fluid: GatsbyImageProps['image'];
  title: string;
}

const PostCoverImage: React.FC<IPostCoverImageProps> = ({ fluid, title }) => {
  return (
    <figure id="article-cover-image" className={styles.coverImage}>
      <GatsbyImage
        image={fluid}
        alt={title}
        imgStyle={{ borderRadius: '.25rem' }}
      />
    </figure>
  );
};

export default PostCoverImage;
