import {
  ConstructMessage,
  BuildSocialLinks,
  IContent,
  ISocialLinks,
} from './types';

const constructMessage: ConstructMessage = ({ text, url }: IContent) => ({
  text: encodeURI(`${text} \n\nRead more at ${url}`),
  url: url,
});

const buildSocialLinks: BuildSocialLinks = ({ text, url }: IContent) => {
  return {
    twitter: `https://twitter.com/intent/tweet?text=${text}`,
    facebook: `https://facebook.com/sharer/sharer.php?caption=${text}&u=${url}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
    whatsapp: `https://wa.me/?text=${text}`,
    pocket: `https://getpocket.com/edit?url=${url}`,
  };
};

// TODO: Properly type the pipe function.
const pipe =
  (...functions: Function[]) =>
  (content: IContent): any =>
    functions.reduce(
      (currentValue, currentFunction) => currentFunction(currentValue),
      content
    );

const generateSocialLinks = (content: IContent) =>
  pipe(constructMessage, buildSocialLinks)(content);

export default generateSocialLinks;
