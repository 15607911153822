import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'gatsby';

import styles from './Nav.module.scss';

interface NavProps {
  currentLocation?: 'Blog' | 'Work';
  returnLocation: 'blog' | 'home';
}

/**
 * Navbar Component.
 */
const Nav: FunctionComponent<NavProps> = ({
  currentLocation,
  returnLocation,
}: NavProps): JSX.Element => {
  const location: string | undefined =
    currentLocation && ` / ${currentLocation}`;
  const goBackLocation: string = returnLocation === 'home' ? `/` : `/blog/`;

  return (
    <Fragment>
      <nav className={styles.nav}>
        <h1 className={styles.nav_go_back}>
          <Link to={goBackLocation}>←</Link>
        </h1>
        <h1 className={styles.nav_title}>{location}</h1>
      </nav>
    </Fragment>
  );
};

export default Nav;
