import React, { FC, Fragment } from 'react';
import { Twitter, Facebook, Linkedin, WhatsApp, Pocket } from '../Icons';
import generateSocialLinks from '../../utils/generateSocialLinks';
import styles from './Share.module.scss';

interface IShareProps {
  content: string;
  url: string;
}

interface IShareLinkProps {
  iconComponent: JSX.Element;
  link: string;
  platform: string;
  action: string;
}

const Share: FC<IShareProps> = (props) => {
  const { content, url } = props;
  const links = generateSocialLinks({ text: content, url });
  const linksData = [
    {
      platform: 'Twitter',
      iconComponent: <Twitter />,
      href: links.twitter,
      action: 'Share on Twitter',
    },
    {
      platform: 'Facebook',
      iconComponent: <Facebook />,
      href: links.facebook,
      action: 'Share on Facebook',
    },
    {
      platform: 'LinkedIn',
      iconComponent: <Linkedin />,
      href: links.linkedin,
      action: 'Share on LinkedIn',
    },
    {
      platform: 'WhatsApp',
      iconComponent: <WhatsApp />,
      href: links.whatsapp,
      action: 'Share on WhatsApp',
    },
    {
      platform: 'Pocket',
      iconComponent: <Pocket />,
      href: links.pocket,
      action: 'Add to Pocket',
    },
    // Since the body cannot have much of a content,
    // for now, email sharing have to be disabled.
    // {
    //     platform: 'Email',
    //     iconComponent: <Email />,
    //     href: links.email,
    // },
  ];

  return (
    <div id="sharing-options" className={styles.container}>
      <div className={styles.text}>
        Share <div className={styles.seperator}></div>
      </div>
      <div className={styles.icons}>
        {linksData.map((link, index) => (
          <ShareLink
            key={`${link.platform}-${index}`}
            iconComponent={link.iconComponent}
            link={link.href}
            platform={link.platform}
            action={link.action}
          />
        ))}
      </div>
    </div>
  );
};

const ShareLink: FC<IShareLinkProps> = (props) => {
  const { iconComponent, link, platform, action } = props;

  return (
    <Fragment>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-platform={platform}
        data-action={action}
        title={action}
      >
        {iconComponent}
      </a>
    </Fragment>
  );
};

export default Share;
