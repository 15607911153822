import React from 'react';
import PropTypes from 'prop-types';
import _ from '../../utils';
import styles from './Post.module.scss';

export interface IPostHeaderProps {
  title: string;
  date: string;
  timeToRead: number;
}

const PostHeader: React.FC<IPostHeaderProps> = ({
  title,
  date,
  timeToRead,
}) => {
  return (
    <header className={styles.header}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.stats}>
        {date} · {`${timeToRead} ${_.inflect('min', 'mins', timeToRead)} read`}
      </p>
    </header>
  );
};

PostHeader.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
};

export default PostHeader;
