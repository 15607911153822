import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './ReadMorePost.module.scss';

const ReadMorePost = ({
  readMorePostFluid,
  readMorePostDescription,
  readMorePostTitle,
  readMorePostSlug,
}) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>
        Read More <div className={styles.inlineSeperator}></div>
      </div>
      <Link
        to={`/blog/${readMorePostSlug}/`}
        className="recommended-article"
        aria-label={readMorePostTitle}
        role="article"
      >
        <article className={styles.post}>
          <GatsbyImage
            className={styles.post_image}
            image={readMorePostFluid}
            alt={readMorePostTitle}
          />
          <header>
            <h2 className={styles.post_title}>{readMorePostTitle}</h2>
            <p className={styles.post_description}>{readMorePostDescription}</p>
          </header>
        </article>
      </Link>
    </section>
  );
};

export default ReadMorePost;
